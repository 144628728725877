.business-details-container {
    height: 500px;
    width: 90%;
}

#map {
    height: 300px;
    width: 100%;
}

h1.is-main {
    color: var(--main-color);
    font-size: 30px;
    margin-bottom: 20px;
}

h2.is-black {
    color: var(--black);
    font-size: 20px;
    margin-bottom: 20px;
}

.table {
    width: 100%;
    background-color: var(--white);
    color: var(--main-color);
}

th {
    color: var(--main-color) !important;
}

td {
    color: var(--black) !important;
}

.column .subtitle {
    margin-top:5px
}