.gallery-container {
    width: 90%;
}

.gallery-block {
    margin: 0 auto;

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    .grid-sizer,
    .lg-item {
        width: 33.33%;
    }

    .lg-item {
        float: left;
        margin-bottom: 10px;
    }

    .lg-item img {
        display: block;
        max-width: 100%;
    }
}