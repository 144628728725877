@import "https://cdn.jsdelivr.net/npm/bulma@1.0.0/css/bulma.min.css";

html {
  --main-color: #004225;
  --secondary-color: #FFB000;
  --third-color: #FFCF9D;
  --black: #1a1a1a;
  --white: #F5F5DC;
  background-color: var(--white);
}
