nav {
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75); */
    /* zindex have to be higher than map */
    z-index: 1000;
}

#navbar-logo {
    height: 50px;
    max-height: 50px;
    padding: 5px;
}

.navbar-item:hover {
    background-color: transparent !important;
}

.navbar.is-main {
    background-color: var(--white) !important;
    height: 70px;
}

.navbar-item .is-main {
    color: var(--main-color) !important;
    padding-left: 5px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
    font-size: 20px;
}

.navbar-item .is-main:hover {
    transform: scale(1.1);
}



.navbar-end {
    padding-right: 20px;
}

.navbar-name {
    font-size: 30px;
    color: white;
}

#mobile-menu {
    background-color: var(--main-color) !important;
    width: 100%;
    position: fixed;
    top: 70px;
    left: 0;
    z-index: 1000;
    display: none;
}

.mobile-menu-item {
    color: white !important;
    font-size: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    transition: all 0.3s ease 0s;
}

.mobile-menu-item:hover {
    background-color: var(--main-color) !important;
    transform: scale(1.1);
}

.mobile-menu-item a {
    color: white !important;
}

#mobile-menu-button {
    font-size: 30px;
    float: right;
    margin-top: -60px;
    margin-right: 10px;
    color: var(--main-color);
    border-radius: 10px;
}

#mobile-menu-button:hover {
    background-color: var(--main-color) !important;
    color: var(--white);
}

#mobile-menu-button:focus {
    background-color: var(--main-color) !important;
}