.intro-container {
    width: 100%;
    height: calc(100vh);
    position: relative;
    background-color: black;
}

#intro-image {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    filter: brightness(0.6);
}

.intro-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh);
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
}

.intro-overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 90%;
}

/* on computer only */
@media screen and (min-width: 1024px) {
    .intro-overlay h1 {
        font-size: 80px !important;
    }

    .intro-overlay h2 {
        font-size: 40px !important;
    }
}

.button.is-main {
    background-color: var(--main-color) !important;
    color: var(--white) !important;
    font-size: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    transition: all 0.3s ease 0s;
    border: 0;
}

.button.is-main:hover {
    transform: scale(1.1);
}

.button.is-secondary {
    background-color: var(--white) !important;
    color: var(--main-color) !important;
    font-size: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    transition: all 0.3s ease 0s;
    border: 0;
}

.button.is-secondary:hover {
    transform: scale(1.1);
}

#phone-button {
    margin-left: 20px;
}

h3 {
    font-size: 25px;
}

/* buttons on mobile */
@media screen and (max-width: 1024px) {
    .button.is-main {
        font-size: 25px;
    }

    .button.is-secondary {
        font-size: 25px;
    }

        #phone-button {
            margin-left: 0px;
        }
}
